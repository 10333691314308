import React, {useState, useEffect, useRef} from 'react'
import {
  SubscribeMain,
  SubscribeFormWrapper,
  SubscribeFormInner,
  SubscribeLabel,
  SubscribeInput,
  SubscribeSubmit,
  Message
} from './index.style'
import Spacer from '@components/Spacer'
import IconArrowRight from '@svgs/IconArrowRight'
import IconTick from '@svgs/IconTick'
import { Heading4Serif } from '@components/TextStyles'
import { useForm } from 'react-hubspot'

const SubscribeForm = ({text}) => {

	const [submitted, setSubmitted] = useState(false)
	const [message, setMessage] = useState()
	const [messgaeStyle, setMessageStyle] = useState([])
	const [canSend, setCanSend] = useState();

	const formRef = useRef();
	const emailInput = useRef();

	useEffect(() => {
    if (submitted) formRef.current.reset()
  }, [submitted])

	const { data, isLoading, isError, handleSubmit } = useForm({
	    portalId: '25832621',
	    formId: 'b03eebce-50fa-451c-a365-8e0005326a77'
	})

	useEffect(()=>{
	    if(data?.data.inlineMessage === "<p>Thanks for signing up to our latest news and updates!</p>") {
	    	setSubmitted(true)
	    	setMessageStyle({color: 'green'})
	    }else {
			setMessageStyle({color: 'red'})
	    }
	    setMessage(data?.data.inlineMessage)
	},[data]);

	const validateEmail = text => {
      const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (text.match(validRegex)) {
          return true;
      } else {
          return false;
      }
  }

  const checkValidate = () => {
      var check = true;
      if(emailInput.current) {
          if(!emailInput.current.value) {
              check = false;
          }else {
              check = validateEmail(emailInput.current.value);
          }
          return check;
      }
      return false;
  }

  const handleChange = () => {
      setCanSend(checkValidate());
  }

	return (
		<SubscribeMain>
			{text && (
		        <>
		          <Heading4Serif maxWidth={14.194}>{text}</Heading4Serif>

		          <Spacer size={[10, 30]} />
		        </>
		    )}
			<SubscribeFormWrapper>
				<form onSubmit={handleSubmit} ref={formRef}>
					<SubscribeFormInner id="mc_embed_signup_scroll" submitted={submitted}>
						<div>
		            <SubscribeInput
		                type="email"
		                name="email"
		                ref={emailInput}
		                placeholder={
		                  submitted
		                    ? 'Thanks for sliding into our DMs'
		                    : 'Email Address'
		                }
		                tabIndex={submitted ? -1 : undefined}
		                id="subscribe-EMAIL"
		                onChange={() => handleChange()}
		                required
		            />
						</div>
						{canSend ?
							(<SubscribeSubmit
	              	type="submit"
	              	value="Subscribe"
	              	name="subscribe"
	              	id="mc-embedded-subscribe"
	              	tabIndex={submitted ? -1 : undefined}
	            	/>)
			         :
			         (<SubscribeSubmit
	              	type="submit"
	              	value="Subscribe"
	              	name="subscribe"
	              	id="mc-embedded-subscribe"
	              	tabIndex={submitted ? -1 : undefined}
	              	disabled
	            	/>)
						}
						

			            {submitted ? (
			              <IconTick responsive={false} />
			            ) : (
			              <IconArrowRight width={11} height={11} responsive={false} />
			            )}
					</SubscribeFormInner>
				</form>
			</SubscribeFormWrapper>
		</SubscribeMain>
	)
}

export default SubscribeForm