import React, { useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import FontFaceObserver from 'fontfaceobserver'

// Components
import { useStore, updatePathname, updatePopState, hideMobileNav, loadFonts } from '@Store'
import TransitionMask from '@components/PageTransitionMask'
import Gridlines from '@components/Gridlines'
import Header from '@components/Header'
import SmoothScroll from '@components/SmoothScroll'

// Fonts
import National2Woff2 from '../../../static/fonts/National_2/national-2-regular.woff2'
import National2ExtraboldWoff2 from '../../../static/fonts/National_2/national-2-extrabold.woff2'
import SignifierLightWoff2 from '../../../static/fonts/Signifier/signifier-light.woff2'

// Styles
import { GlobalStyles } from '@styles/GlobalStyles.style'
import { font } from '@styles/vars/font.style'
import Footer from '@components/Footer'
import { updateResized } from '@Store/'
import debounce from 'lodash/debounce'
import { isDesktop, isTablet } from 'react-device-detect'
import Display from '@components/_OurWorks/CaseStudies/Display'
import Display2 from '@components/_Insights/Insights/Display'

const Layout = ({ children, location }) => {
  const [store, dispatch] = useStore()
  const { smoothScroll: smoothScrollIsActive, showDisplayControls } = store
  const $header = useRef()
  const $footer = useRef()

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      updatePathname(
        dispatch,
        window.location.pathname.charAt(0) === '/'
          ? window.location.pathname.substring(1)
          : window.location.pathname
      )
      updatePopState(dispatch)
    },
    [dispatch]
  )

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const setHeaderHeight = () => {
    document.documentElement.style.setProperty(
      '--headerHeight',
      `${$header.current.offsetHeight}px`
    )
  }

  const setFooterHeight = () => {
    document.documentElement.style.setProperty(
      '--footerHeight',
      `${$footer.current.offsetHeight}px`
    )
  }

  const handleWebfontLoad = useCallback(() => {
    const observers = []
    const fontData = {
      [font.sans.family]: { weight: font.sans.weight.regular },
      [font.sans.family]: { weight: font.sans.weight.extrabold },
      [font.serif.family]: { weight: font.serif.weight.light },
    }

    Object.keys(fontData).forEach(family => {
      const data = fontData[family]
      const obs = new FontFaceObserver(family, data)
      observers.push(obs.load())
    })

    Promise.all(observers)
      .then(() => {
        loadFonts(dispatch)
      })
      .catch(err => {
        console.warn('Some critical font are not available', err)
      })
  }, [dispatch])

  const handleResize = useCallback(() => {
    setViewportHeight()
    setFooterHeight()
    setHeaderHeight()
  }, [])

  useEffect(() => {
    const $content = document.querySelector('.tl-edges')

    handleResize()
    handleWebfontLoad()

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        updateResized(dispatch)
      }, 50)
    )

    resizeObserver.observe($content)

    window.addEventListener('resize', handleResize)
    window.addEventListener('popstate', handleBrowserNavigationInteraction)

    return () => {
      resizeObserver.unobserve($content)
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
    }
  }, [
    dispatch,
    handleWebfontLoad,
    handleResize,
    handleBrowserNavigationInteraction,
  ])

  useEffect(() => {
    if (store.fontIsLoaded) {
      handleResize()
    }
  }, [store.fontIsLoaded, handleResize])

  useEffect(() => {
    hideMobileNav(dispatch)
  }, [location])

  const mainHandle = (e) => {
    var event1 = new CustomEvent("mainClicked", {detail: {targets: e.target}});
    window.dispatchEvent(event1);
  }

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <style>{`
          @font-face {
            font-family: '${font.sans.family}';
            src: url(${National2Woff2}) format('woff2');
            font-weight: ${font.sans.weight.regular};
            font-display: swap;
          }

          @font-face {
            font-family: '${font.sans.family}';
            src: url(${National2ExtraboldWoff2}) format('woff2');
            font-weight: ${font.sans.weight.extrabold};
            font-display: swap;
          }

          @font-face {
            font-family: '${font.serif.family}';
            src: url(${SignifierLightWoff2}) format('woff2');
            font-weight: ${font.serif.weight.light};
            font-display: swap;
          }
        `}</style>
        <script
          type="text/javascript"
          src="https://www.research-tree.com/scripts/CorporateWhiteLabelled/embed_v2.js"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
        f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5SMNSHG');</script>`,
          }}
        />
      </Helmet>

      <Gridlines show={false} />

      {(isDesktop || isTablet) && <SmoothScroll callbacks={location} />}

      <TransitionMask />

      <Header ref={$header} pathname={location.pathname} />

      <div
        id="smooth-wrapper"
        className={smoothScrollIsActive ? 'smooth-scroll-is-active' : ''}
      >
        <div id="smooth-content" onClick={mainHandle}>{children}</div>
      </div>

      {(showDisplayControls && (location.pathname === "/our-work" || location.pathname === "/our-work/" || location.pathname === "our-work")) && <Display mobile={true}/>}
      {(showDisplayControls && (location.pathname === "/insights" || location.pathname === "/insights/" || location.pathname === "insights")) && <Display2 mobile={true}/>}
      <Footer ref={$footer} smoothScrollIsActive={smoothScrollIsActive} />
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-eu1.hs-scripts.com/25832621.js"
      ></script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5SMNSHG" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
        `,
        }}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
