import { createGlobalStyle } from 'styled-components'
import { reset } from '@styles/partials/reset.style'
import { typography } from '@styles/partials/typography.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { gatsbyTransitionLinkStyles } from '@styles/partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${gatsbyTransitionLinkStyles}

  :root {
    --reach-tabs: 1; // This disables the Reach UI warning about not including matching CSS files https://reach.tech/styling/
    --reach-dialog: 1;
  }

  * {
    -webkit-marquee-increment: 0vw; // This ensures that clamp values are updated on window resize in Safari
  }

  body {
    background-color: ${colors.white};

    &.disable-scroll {
      overflow: hidden;
    }
  }

  /* #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  } */

  [data-reach-dialog-overlay] {
    z-index: ${zIndex.modal};
  }

  /* Setup fixed hidden footer */
  #smooth-wrapper {
    pointer-events: none;
    z-index: 1;
  }

  #smooth-content {
    pointer-events: none;
    will-change: transform, height;
  }

  .tl-wrapper {
    pointer-events: auto;
  }

  .tl-edges {
    margin-bottom: var(--footerHeight);
    
    .smooth-scroll-is-active & {
      margin-bottom: 0;
      padding-bottom: var(--footerHeight);
    }
  }
  #CookiebotWidget {
    z-index: 30 !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    background-clip: text;
    -webkit-box-shadow: 0 0 0 30px ${colors.charcoal} inset !important;
    -webkit-text-fill-color: ${colors.white} !important;
  }
`
